<template>
  <v-container fluid>
    <!-- ACTIONS -->
    <v-row>
      <v-col align="right">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              @click="selectResource(newLinkTemplate)"
              class="msaBlue white--text"
            >
              <v-icon> mdi-plus </v-icon>
            </v-btn>
          </template>
          Add Resource
        </v-tooltip>
      </v-col>
    </v-row>

    <!-- RESOURCES -->
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="msaBlue white--text"> Resources </v-card-title>
          <v-data-table
            :headers="headers"
            :items="resources"
            disable-filtering
            disable-pagination
            disable-sort
            hide-default-footer
          >
            <template v-slot:[`item.isLink`]="{ item }">
              <a
                v-if="item.isLink"
                :href="$helpers.safeUrl(item.url)"
                target="_blank"
              >
                Link
              </a>
              <v-tooltip v-else bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    @click="downloadResource(item)"
                    color="red"
                    icon
                    small
                  >
                    <v-icon> mdi-file-pdf-box </v-icon>
                  </v-btn>
                </template>
                {{ item.originalFilename }}
              </v-tooltip>
            </template>
            <template v-slot:[`item.isEnabledWeb`]="{ item }">
              {{ item.isEnabledWeb ? 'Published' : 'Unpublished' }}
            </template>
            <template v-slot:[`item.isEnabled`]="{ item }">
              {{ item.isEnabled ? 'Published' : 'Unpublished' }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    @click="selectResource(item)"
                    color="msaBlue"
                    small
                    text
                  >
                    <v-icon> mdi-pencil </v-icon>
                  </v-btn>
                </template>
                Edit
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    @click="deleteResource(item.id)"
                    color="red"
                    small
                    text
                  >
                    <v-icon> mdi-delete </v-icon>
                  </v-btn>
                </template>
                Delete
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <!-- DIALOG -->
    <CompanySettingsResourceDialog
      @onClose="isResourceDialogOpen = false"
      @onChange="getCompanyResources"
      ref="resourceDialog"
    />
  </v-container>
</template>

<script>
import CompanySettingsResourceDialog from '@/components/Menus/CompanySettingsResourceDialog';

export default {
  name: 'CompanySettingsResourcePage',
  components: {
    CompanySettingsResourceDialog,
  },
  data() {
    return {
      resources: [],
      headers: [
        {
          text: 'Title',
          value: 'label',
          align: 'start',
          class: 'headerTextGrey--text lightGrey',
        },
        {
          text: 'Web',
          value: 'isEnabledWeb',
          align: 'center',
          class: 'headerTextGrey--text lightGrey',
          width: '20px',
        },
        {
          text: 'Mobile',
          value: 'isEnabled',
          align: 'center',
          class: 'headerTextGrey--text lightGrey',
          width: '20px',
        },
        {
          text: 'Resource',
          value: 'isLink',
          align: 'center',
          class: 'headerTextGrey--text lightGrey',
          width: '45px',
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          class: 'headerTextGrey--text lightGrey',
          width: '140px',
        },
      ],
      newLinkTemplate: {
        details: '',
        originalFilename: '',
        isEnabled: 0,
        isEnabledWeb: 0,
        isLink: true,
        label: '',
        url: '',
      },
    };
  },
  mounted() {
    this.getCompanyResources();
  },
  methods: {
    downloadResource(resource) {
      const url = 'download-resource-pdf';
      const params = {
        loaderText: 'Loading...',
        resourceId: resource.id,
      };
      const options = {
        responseType: 'blob',
        withCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
      };

      this.$axios.post(url, params, options).then((response) => {
        this.$helpers.previewFileInNewWindow(response.data);
      });
    },
    selectResource(resource) {
      this.$refs.resourceDialog.updateSelectedResource(resource);
    },
    getCompanyResources() {
      const url = 'get-company-resources?format=json';
      const params = {
        loaderText: 'Loading...',
      };

      this.$axios.post(url, params).then((response) => {
        this.resources = [];

        for (const resource of response.data) {
          if (resource.url.trim().length) {
            resource.isLink = true;
          } else {
            resource.isLink = false;
          }

          this.resources.push(resource);
        }
      });
    },
    deleteResource(resourceId) {
      this.$root.showMessage(
        'Confirm',
        'Are you sure you want to delete this resource?',
        () => confirm(),
        () => false,
        'Confirm',
        'Cancel',
      );
      const confirm = () => {
        const params = {
          loaderText: 'Loading...',
          id: resourceId,
        };
        const url = 'delete-company-resource?format=json';
        this.$axios
          .post(url, params)
          .then(() => {
            const index = this.resources.findIndex(
              (resource) => resource.id == resourceId,
            );
            this.resources.splice(index, 1);
          })
          .catch((error) => error);
      };
    },
  },
};
</script>
