<template>
  <v-dialog v-model="dialog" persistent width="500">
    <v-card>
      <v-card-title class="msaBlue white--text"> Details </v-card-title>
      <v-card-text class="py-4">
        <v-row no-gutters>
          <v-col>
            <v-text-field
              v-model.trim="resource.label"
              :rules="rules.label"
              dense
              label="Title"
              outlined
            />
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col>
            <v-text-field
              v-model.trim="resource.details"
              dense
              label="Description"
              outlined
            />
          </v-col>
        </v-row>

        <v-row v-if="resourceType == 'LINK'" no-gutters>
          <v-col>
            <v-text-field
              v-model.trim="resource.url"
              :rules="rules.url"
              dense
              label="Link"
              outlined
            />
          </v-col>
        </v-row>

        <v-row v-else align="center" no-gutters>
          <v-col class="grow mr-2">
            <v-text-field
              v-model.trim="selectedFile.name"
              :rules="rules.file"
              dense
              label="File name"
              outlined
              readonly
            />
          </v-col>
          <v-col class="shrink">
            <v-btn
              @click="$refs.uploader.click()"
              class="white--text msaBlue mb-6"
            >
              UPLOAD
            </v-btn>
            <input
              type="file"
              ref="uploader"
              class="d-none"
              accept="application/pdf"
              @change="updateSelectedFile($event)"
            />
          </v-col>
        </v-row>

        <v-row class="my-3" no-gutters>
          <v-col class="font-weight-bold" cols="12"> Resource type </v-col>
          <v-col>
            <v-btn-toggle
              v-model="resourceType"
              @change="resetSelectedResource"
              color="msaBlue"
              dense
              mandatory
              style="width: 100%"
            >
              <v-btn :value="'PDF'" class="text-capitalize" style="width: 50%">
                PDF
              </v-btn>
              <v-btn :value="'LINK'" class="text-capitalize" style="width: 50%">
                LINK
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>

        <v-row class="my-3" no-gutters>
          <v-col class="font-weight-bold" cols="12"> Publish </v-col>
          <v-col>
            <v-btn-toggle
              :value="initPublishOptions"
              @change="updatePublishOptions"
              color="msaBlue"
              dense
              multiple
              style="width: 100%"
            >
              <v-btn :value="'WEB'" class="text-capitalize" style="width: 50%">
                WEB
              </v-btn>
              <v-btn
                :value="'MOBILE'"
                class="text-capitalize"
                style="width: 50%"
              >
                MOBILE
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>

        <v-row>
          <v-col align="right">
            <v-btn @click="dialog = false" color="red" text> Cancel </v-btn>
            <v-btn
              @click="saveResourceDetails"
              :disabled="isSaveDisabled"
              class="msaBlue white--text"
            >
              Confirm
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'CompanySettingsResourceDialog',
  data() {
    return {
      selectedFile: {},
      resourceType: 'LINK',
      hasFileBeenUploaded: false,
      initPublishOptions: [],
      resource: {
        details: '',
        originalFilename: '',
        isEnabled: 0,
        isEnabledWeb: 0,
        isLink: true,
        label: '',
        url: '',
      },
      unchangedResource: {
        details: '',
        originalFilename: '',
        isEnabled: 0,
        isEnabledWeb: 0,
        isLink: true,
        label: '',
        url: '',
      },
      rules: {
        label: [(v) => this.$helpers.required(v, 'Title')],
        url: [(v) => this.$helpers.required(v, 'Link')],
        file: [(v) => this.$helpers.required(v, 'File')],
      },
      dialog: false,
    };
  },
  computed: {
    isSaveDisabled() {
      let isEmpty, isUnchanged;

      if (this.resourceType == 'LINK') {
        isEmpty = !this.resource.label.trim() || !this.resource.url.trim();

        isUnchanged =
          this.unchangedResource.label == this.resource.label &&
          this.unchangedResource.details == this.resource.details &&
          this.unchangedResource.url == this.resource.url &&
          this.unchangedResource.isEnabled == this.resource.isEnabled &&
          this.unchangedResource.isEnabledWeb == this.resource.isEnabledWeb;

        return isEmpty || isUnchanged;
      }

      isEmpty = !this.resource.label.trim() || !this.selectedFile.name?.trim();

      isUnchanged =
        this.unchangedResource.label == this.resource.label &&
        this.unchangedResource.details == this.resource.details &&
        this.unchangedResource.isEnabled == this.resource.isEnabled &&
        this.unchangedResource.isEnabledWeb == this.resource.isEnabledWeb;

      if (this.hasFileBeenUploaded && !isEmpty) {
        return false;
      }

      return isEmpty || isUnchanged;
    },
  },
  methods: {
    resetSelectedResource() {
      this.hasFileBeenUploaded = false;
      this.selectedFile = {};

      if (this.resource.isLink) {
        this.resource.url = '';
        return;
      }

      this.selectedFile.name = this.resource.originalFilename;
    },
    updatePublishOptions(event) {
      this.resource.isEnabled = 0;
      this.resource.isEnabledWeb = 0;

      if (event.includes('WEB')) {
        this.resource.isEnabledWeb = 1;
      }

      if (event.includes('MOBILE')) {
        this.resource.isEnabled = 1;
      }
    },
    saveResourceDetails() {
      if ('id' in this.resource) {
        this.updateResource();
        return;
      }

      this.addResource();
    },
    addResource() {
      if (this.resourceType == 'LINK') {
        this.handleLinkRequest('add-company-resource-link?format=json');
        return;
      }
      this.handleFileRequest('add-company-resource-pdf?format=json');
    },
    updateResource() {
      if (this.resourceType == 'LINK') {
        this.handleLinkRequest('update-company-resource-link?format=json');
        return;
      }
      this.handleFileRequest('update-company-resource-pdf?format=json');
    },
    async updateSelectedFile(event) {
      let file = event.target.files[0];
      if (!file) {
        return;
      }

      try {
        file = await this.$helpers.processFile(
          file,
          this.$constants.ACCEPTED_MIME_TYPES.PDF,
          true,
        );
      } catch (error) {
        if (error.message) {
          this.$root.showMessage(
            'Error',
            error.message,
            () => null,
            null,
            'ok',
          );
          return;
        }
      }

      this.selectedFile = file;
      this.hasFileBeenUploaded = true;
    },
    processFileForUpload() {
      const headers = {
        'Content-Type': 'multipart/form-data',
      };

      const params = new FormData();
      params.append('label', this.resource.label.trim());
      params.append('details', this.resource.details.trim());
      params.append('mimeType', this.selectedFile.type);
      params.append('originalFilename', this.selectedFile.name);
      params.append('resourceId', this.resource.id);
      params.append('jwtToken', this.$store.getters.jwtToken);
      params.append('loaderText', 'Uploading...');
      params.append('isEnabled', this.resource.isEnabled);
      params.append('isEnabledWeb', this.resource.isEnabledWeb);

      // Will only have size value if PDF is being uploading or replaced
      if ('size' in this.selectedFile) {
        params.append('file', this.selectedFile);
      }

      return { headers: headers, params: params };
    },
    handleLinkRequest(url) {
      const params = {
        loaderText: 'Loading...',
        resource: this.resource,
      };

      this.$axios
        .post(url, params)
        .then(() => {
          this.$emit('onChange');
          this.dialog = false;
        })
        .catch((error) => error);
    },
    handleFileRequest(url) {
      const requestDetails = this.processFileForUpload();

      this.$axios
        .post(url, requestDetails.params, requestDetails.headers)
        .then(() => {
          this.$emit('onChange');
          this.dialog = false;
        })
        .catch((error) => error);
    },
    updateSelectedResource(resource) {
      this.dialog = true;

      this.selectedFile = {};

      this.resource = JSON.parse(JSON.stringify(resource));
      this.unchangedResource = JSON.parse(JSON.stringify(resource));

      if (this.resource.originalFilename.length) {
        this.selectedFile.name = this.resource.originalFilename;
      }

      this.initPublishOptions = [];
      if (this.resource.isEnabled) {
        this.initPublishOptions.push('MOBILE');
      }
      if (this.resource.isEnabledWeb) {
        this.initPublishOptions.push('WEB');
      }

      this.resourceType = resource.isLink ? 'LINK' : 'PDF';

      // Needed to display file name in text field
      if (!resource.isLink) {
        this.selectedFile.name = resource.originalFilename;
      }
    },
  },
};
</script>
